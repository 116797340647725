import { JSX }		from "preact";
import { useState }	from "preact/hooks";
import { route }	from "preact-router";
import { Link }		from "preact-router/match";

import * as Arrays	from "ts-base/array";

import * as common	from "@spaengler/shared/CommonModel";

import { Messages }	from "@spaengler/frontend/locale";

type ToolbarProps = Readonly<{
	position:	string,
	msg:		Messages,
	screen:		common.EditionScreen,
	statistics:	common.Statistics,
}>;

export const Toolbar = (props:ToolbarProps):JSX.Element => {
	const [ error, setError ] = useState<boolean>(false);

	const handleFocus = (ev:JSX.TargetedEvent<HTMLInputElement, FocusEvent>):void => {
		ev.currentTarget.select();
	};

	const onBlur = ():void => {
		setError(false);
	};

	const goToPage = (ev:JSX.TargetedEvent<HTMLInputElement, InputEvent>):void => {
		// console.log("goToPage");
		const page = parseInt(ev.currentTarget.value, 10);
		const book = props.statistics[props.screen.bookNumber];
		if (page >= book.firstPage && page <= book.lastPage) {
			// actions.getPage(props.screen.bookNumber, page);
			route(`/transcription/${props.screen.bookNumber}/${page}`);
		}
		else {
			console.log("page does not exist:", page);
			setError(true);
		}
	};

	const msg			= props.msg;
	const screen		= props.screen;
	const statistics	= props.statistics;

	const currentBook		= screen.bookNumber;
	const currentPage		= screen.pageNumber;
	const currentBookStat	= statistics[currentBook];

	const bookNumbers:ReadonlyArray<number>	= Arrays.sortBuiltin(Object.values(statistics).map((it:common.BookStats) => it.bookNumber));
	// console.log("bookNumbers", bookNumbers);
	const isFirstBook	= currentBook === bookNumbers[0];
	const isLastBook	= currentBook === bookNumbers[bookNumbers.length - 1];
	// console.log("isFirstBook", isFirstBook, isLastBook );
	const previousBook	= !isFirstBook	? currentBook - 1 : null;
	const nextBook		= !isLastBook	? currentBook + 1 : null;

	const isFirstPage	= currentPage === currentBookStat.firstPage;
	const isLastPage	= currentPage === currentBookStat.lastPage;
	const previousPage	= !isFirstPage	?	currentPage - 1	: null;
	const nextPage		= !isLastPage	?	currentPage + 1 : null;

	// TODO all the defined checks after the !isFooBar expressions are actually included in this same expression,
	// but typescript does not know about that. can we teach is somehow, or get rid of the flag checks?

	const getUrl = (book: number, page:number) => "/transcription/" + book + "/" + page;

	const previousBookNav	=
		!isFirstBook && previousBook !== null && previousBook !== 0
		?	getUrl(previousBook, statistics[previousBook].firstPage)
		:	null;
	const previousBookNav2	=
		!isFirstBook && previousBook !== null && previousBook !== 0
		?	getUrl(previousBook, statistics[previousBook].lastPage)
		:	null;
	const nextBookNav		=
		!isLastBook	&& nextBook !== null && nextBook !== 0
		?	getUrl(nextBook, statistics[nextBook].firstPage)
		:	null;
	const firstPageNav		=
		!isFirstPage
		?	getUrl(currentBook, currentBookStat.firstPage)
		:	null;
	const lastPageNav		=
		!isLastPage
		?	getUrl(currentBook, currentBookStat.lastPage)
		:	null;
	const previousPageNav	=
		!isFirstPage && previousPage !== null
		?	getUrl(currentBook, previousPage)
		:	null;
	const nextPageNav		=
		!isLastPage	&& nextPage !== null
		?	getUrl(currentBook, nextPage)
		:	null;


	const previousBookOrFirstPage		= previousBookNav	!== null ? previousBookNav	: firstPageNav;
	const nextBookOrLastPage			= nextBookNav		!== null ? nextBookNav		: lastPageNav;
	const previousPageOrPreviousBook	= previousPageNav	!== null ? previousPageNav	: previousBookNav2;
	const nextPageOrNextBook			= nextPageNav		!== null ? nextPageNav		: nextBookNav;

	return (
		<div class={ `transcription-toolbar ${props.position}` } aria-controls="aria-transcription-display">
			<div class="transcription-toolbar-left-page">
				<div class="transcription-toolbar-left-col">
					<Link
						title={msg.previousBook}
						disabled={previousBookOrFirstPage === null}
						class="transcription-button"
						activeClassName="active"
						{...(previousBookOrFirstPage !== null ? { href: previousBookOrFirstPage } : {})}
					>
						<span class="fa fa-angle-double-left" aria-hidden="true"></span>
						<span class="sr-only">{msg.previousBook}</span>
					</Link>
					<Link
						title={msg.previousPage}
						disabled={previousPageOrPreviousBook === null}
						class="transcription-button"
						activeClassName="active"
						{...(previousPageOrPreviousBook !== null ? { href: previousPageOrPreviousBook } : {})}
					>
						<span class="fa fa-angle-left" aria-hidden="true"></span>
						<span class="sr-only">{msg.previousPage}</span>
					</Link>
				</div>
				<div class="transcription-toolbar-right-col">
					<label for="transcription-input-page" class="form-label ml">{msg.page}</label>
					<div class="form-field-wrapper ml">
						<input
							onFocus={handleFocus}
							onBlur={onBlur}
							onChange={goToPage}
							name="pagenumber"
							id="transcription-input-page"
							type="text"
							class="form-input-text number"
							value={currentPage}
						/>
						<span class="ml">{msg.of} {currentBookStat.lastPage}, {msg.book} {currentBook}</span>
						{ error &&
							<div id="transcription-input-page-error" class="form-error popup popup-bottom">
								{msg.errorPageDoesntExist}
							</div>
						}
					</div>
				</div>
			</div>
			<div class="transcription-toolbar-right-page">
				<div class="transcription-toolbar-left-col">
					<Link class="text-link" activeClassName="active" href={`/db/?book=${currentBook}&page=${currentPage}`}>{msg.transcriptionLink}</Link>
				</div>
				<div class="transcription-toolbar-right-col">
					<Link
						title={msg.nextPage}
						disabled={nextPageOrNextBook === null}
						class="transcription-button"
						activeClassName="active"
						{...(nextPageOrNextBook !== null ? { href: nextPageOrNextBook } : {})}
					>
						<span class="fa fa-angle-right" aria-hidden="true"></span>
						<span class="sr-only">{msg.nextPage}</span>
					</Link>
					<Link
						title={msg.nextBook}
						disabled={nextBookOrLastPage === null}
						class="transcription-button"
						activeClassName="active"
						{...(nextBookOrLastPage !== null ? { href: nextBookOrLastPage } : {})}
					>
						<span class="fa fa-angle-double-right" aria-hidden="true"></span>
						<span class="sr-only">{msg.nextBook}</span>
					</Link>
				</div>
			</div>
		</div>
	);
};
