import { JSX }	from "preact";

export const DeIntro = ():JSX.Element => (
	<div>
		<h1>Einleitung</h1>
		<p>Im Stadtarchiv Salzburg sind vier Ausgabenbücher (Breite jeweils 16,5 cm, Höhe 39 bis 41 cm) überliefert, die im Haushalt des Tuch- und Seidenhändlers Franz Anton Spängler von 1733 bis 1785, d. h. über einen Zeitraum von mehr als fünf Jahrzehnten, geführt wurden (Stadtarchiv Salzburg, Privatarchivalien 1172).</p>
		<p>Franz Anton Spängler (1705–1784) wohnte mit seiner Familie im Zentrum der Stadt Salzburg am Alten Markt und zählte zu den vermögendsten Bürgern; er hinterließ 1784 immerhin ein Gesamtvermögen von mehr als 200.000 Gulden (Reith et al. 2015). Dem Haushalt gehörten neben der jeweiligen Ehefrau die Kinder und Stiefkinder an, ebenso das Hauspersonal sowie auch die Bediensteten der Handlung, in aller Regel mehr als zehn Personen (Reith et al. 2016). </p>
		<p>In den vier Ausgabenbüchern wurden anhand von rund 21.000 Einträgen alltägliche und ungewöhnliche Ausgaben des Haushalts verzeichnet. Diese betrafen vor allem die Ernährung, den Konsum von Genussmitteln, dann die Anschaffung, Pflege und Reparatur von Hausrat und Kleidung sowie auch die Versorgung mit Grundbedürfnissen wie Brennholz und Wasser. Ebenso notierte man die Beschäftigung und Entlohnung des häuslichen Personals und der Hauslehrer, den Bezug von Arzneimitteln sowie die Honorare für die Ärzte, Barbierer und Hebammen. Daneben finden wir Eintragungen zu Geschenken und Almosen. Daher wechseln regelmäßige und unregelmäßige Ausgaben. Die Ausgaben für die einzelnen Posten reichen von wenigen Denaren und Kreuzern (z. B. für Trinkgelder) bis hin zu größeren Posten, wenn z. B. dem Hofmetzger 200 Gulden für den Fleischbezug a conto bezahlt wurden. Der höchste Betrag (307 Gulden) ging 1755 – wohl für Schmuck – an den Goldschmied Johann Michael Mayr anlässlich der dritten Hochzeit von Franz Anton Spängler mit Maria Theresia Traunbauer, zu der Leopold Mozart die Hochzeitsmusik komponierte, die im Haushaltsbuch ebenfalls abgerechnet wurde.</p>

		<h2>Alltags- und Konsumgeschichte und Digital Humanities</h2>
		<p>Ausgabenbücher ermöglichen einen direkten Zugang zu den Konsumenten und ihrer Lebenswelt. Sie erlauben z. B. Einblicke in die Vorlieben bzw. Präferenzen, die Verfügbarkeit, den Bezug, die Preise und Preisschwankungen. Die Bedeutung der Haushaltsbücher als Quellen für die Geschichte der Lebenshaltung ist schon in der älteren Forschung betont worden (Bräuer 1915). Das Interesse der Forschung konzentrierte sich jedoch – auch aufgrund der Überlieferung – meist auf große Persönlichkeiten wie Anton Tucher (Loose 1877), Voltaire (Besterman 1968), Johann Caspar Goethe (Holtzhauer 1973), Thomas Jefferson (Bear / Stanton 1997) oder Robert Schumann (Nauhaus 1988). Mit zunehmendem Interesse an der Alltagsgeschichte und im Kontext der Entwicklung der historischen Konsumforschung (Sandgruber 1982, Brewer / Porter 1993, Schmidt-Funke 2019) sind in der Folge weitere Haushaltsbücher als Edition vorgelegt bzw. erschlossen worden (Händel / Herrmann 1992, Spiegel 1997, Kommer 1998, Kink 2007, Spalding / Spalding 2015).</p>
		<p>Einige Haushaltsbücher liegen als Faksimile vor, andere in transkribierter Form bzw. als kritische Edition. In einigen Fällen ist nur das Original veröffentlicht worden, in anderen Fällen liegt eine Auswertung vor, ohne dass man jedoch auf das Original zurückgreifen könnte. Bisher vorliegende Auswertungen bzw. Editionen geben wichtige Einblicke, sie sind jedoch für den Leser von begrenzter Nutzbarkeit, denn viele Fragstellungen sind nur durch die Koppelung von Digitalisat, Transkription und Datenbank zu beantworten. Der digitale Zugang bietet hier neue Möglichkeiten der Darstellung, der Auswertung und der Nutzung bis hin zum Einsatz als Quelle in der universitären Lehre. Angesichts der Möglichkeiten der Darstellung und der Analyse, die sich nun durch die Digital Humanities ergeben, schien es uns angebracht, Transkription und Datenbank nicht in Buchform zu publizieren, sondern das Potential einer digitalen Edition auszuschöpfen (Vogeler 2015 und das Projekt Basler Stadtrechnungen online: Burghartz 2015).</p>

		<h2>Aufbau und Spezifika der Ausgabenbücher</h2>
		<p>Das erste Buch (1733 bis 1750) ist auf der Titel- bzw. Umschlagseite als „Hauß=Uncosten Conto Biechl – 1733“ bezeichnet und mit dem Monogramm FAS versehen. Das Buch ist von Franz Anton Spängler angelegt und geführt worden, wobei schon bald seine Ehefrau Maria Katharina (geb. Ingerl, verw. Prötz) bis zu ihrem Tod 1743 Einträge vornahm. Ab 1744 bis zu ihrem Tod 1753 führte überwiegend die zweite Ehefrau, Anna Elisabeth (geb. Egger, verw. Lang), das Haushaltskonto, und schließlich nahm ab 1755 die dritte Ehefrau, Maria Theresia, bis zu ihrem Tod 1780 die meisten Eintragungen vor. Zwischenzeitlich finden sich Einträge des Sohnes Franz Joseph Christian. Franz Anton führte das Haushaltsbuch dann bis kurz vor seinem Tod im April 1784, worauf es sein Sohn Franz Joseph Christian und seine Tochter Maria Franziska unter dem Abschnitt Interregnum noch bis 1785 fortsetzten. Die Ausgabenbücher weisen nach dem Tod der Ehefrauen kurze Lücken auf. Während bei Franz Anton die geübte, regelmäßige und barocke Schrift des Kaufmanns erkennbar ist, so sind z.B. die Schriftzüge Maria Theresias anfangs eher ungelenk. Die verschiedenen Handschriften können in der Transkription (in der Folge als Textedition bezeichnet) durch die <a href="#colorCode" class="text-link">farbliche Kennzeichnung</a> bzw. in der Datenbank durch die Zuordnung zu einem Kürzel (FAS, MKS, AES etc.) unterschieden werden, so dass die jeweiligen Autoren/innen zu erkennen sind. Die Orthographie wurde beibehalten (vgl. Editionsprinzipien), um auch sprachliche Eigenheiten und die dialektale Färbung zu erhalten. Zeitgenössische Begriffe sind über die Verschlagwortung zu erschließen.</p>
		<p>Die Art der Buchhaltung bzw. der Verzeichnung der Ausgaben erfolgte über die vier Bücher hinweg durchweg ähnlich, und es gibt nur wenige Veränderungen. Kopfzeile und Fußzeile enthalten nur in den ersten Jahren den Übertrag (Transporto), später wird darauf verzichtet. Anfangs erfolgt die Untergliederung nach Monaten mit Zwischensummen, die später mitunter nachgetragen oder mit Bleistift ergänzt wurden. Mit Beginn des neuen Jahres wurde meist eine neue Seite begonnen und die Neujahresgeschenke an die Frau, die Kinder, die Bedienten, das häusliche Personal, die Boten und andere Dienstleister bis hin zu den Nachtwächtern verzeichnet. Manche Beträge wurden wohl erst später ausbezahlt und dann abgehakt. Auf die Liste der Neujahrsgeschenke folgen die Einträge zu den Jahresbestallungen (z.B. an den Perückenmacher und den Barbierer), denen dann wieder die journalartigen Einträge folgen.</p>
		<p>Personen sind in den Ausgabenbüchern nur selten mit ihrem vollen Namen genannt. Manchmal sind nur die Funktionen (Hofstaller) oder Leistungen (für die Wösch) aufgeführt, und häufig – wie beim häuslichen Personal – auch nur die Vornamen. Die Namen ließen sich mitunter aus späteren Nennungen sowie anhand anderer Quellen rekonstruieren und wurden in der Datenbank ergänzt (vgl. Reith et al. 2016). Weiterführende Angaben zu zahlreichen Personen finden sich im Personenregister (vgl. unten).</p>

		<h2><a id="aboutDb">Hinweise zu Textedition und der Datenbank</a></h2>
		<p>In der <a class="text-link" href="/transcription/1/3">Textedition</a> ist die originale Reihenfolge der Einträge beibehalten worden, um insbesondere Aufbau, Charakter und Führung der Ausgabenbücher zu dokumentieren, d. h. auch Durchstreichungen (soweit der durchgestrichene Text erkennbar ist), Sonderzeichen und Randbemerkungen wurden wiedergegeben (Sahle 2017). Parallel zur Transkription wird das Digitalisat der jeweiligen Seite angezeigt.</p>
		<p>Die <a class="text-link" href="/db">Datenbank</a> erschließt die Transkription über eine Volltextsuche und verschiedene Auswahlmöglichkeiten. Die Datenbank ermöglicht auch Berechnungen, wie z.B. von monatlichen Ausgaben oder von Ausgaben für bestimmte Produkte oder Dienstleistungen. Einträge ohne Geldbetrag und reine Texteinträge (Transporto oder Zwischensummen) sind nicht Teil der Datenbank. Eine zentrale Funktion der Datenbank ist die Schlagwortsuche <a class="text-link" target="_blank" href="assets/ASO_keywords_1_2020.pdf">(vgl. dazu die Liste der vergebenen Schlagworte)</a>, die für die Bereiche "Art der Zahlung", Art der Leistung/Anlass/Objekt" und "Empfänger" vergeben wurden.</p>
		<p>Die elektronische Edition wird durch ein <a class="text-link" href="/persons/A">Personenregister</a> ergänzt, das über 500 Individuen erfasst, die in den Ausgabenbüchern namentlich genannt werden, und die meist mehrfach vorkommen. Wenn nicht anders vermerkt, dann ist die Stadt Salzburg Herkunfts- und Aufenthaltsort. Die eckigen Klammern zu Beginn des Eintrags bezeichnen die Nennung der Person im angegebenen Jahr in den Ausgabenbüchern. Wird im Personenregister in Klammer eine Salzburger Pfarre genannt (z.B. Dompfarre, St. Andrä oder Salzburg-Nonntal), dann bezieht sich diese Angabe auf die Kirchenbücher der betreffenden Pfarre, die unter matricula online (data.matricula-online.eu/de/oesterreich/salzburg) zugänglich sind. Zusätzlich herangezogen wurde die Universitätsmatrikel (Redlich 1933), die Bürgerbücher der Stadt Salzburg, die Stadtratsprotokolle, die Seelenbeschreibungen, die Steuerverzeichnisse und Verlassenschaftsinventare sowie die Forschungsliteratur (wie z.B. Barth-Scalmani 1992, Breitinger et al. 2009). Wenn keine Literatur- und Quellenangaben angeführt sind, dann stammen die Informationen zur Person direkt aus den Ausgabenbüchern. Die im Personenregister verwendeten Abkürzungen sind unten angeführt.</p>

		<h2>Editionsprinzipien</h2>
		<p>Der Text wurde weitgehend in seiner ursprünglichen Orthographie belassen. Um eine leichtere Lesbarkeit zu gewährleisten, wurden einige Modifikationen vorgenommen. Groß- und Kleinschreibung wurden angepasst, die Rechtschreibung jedoch weitgehend beibehalten, wobei lediglich marginale Änderungen (etwa bzgl. s, ss und ß) vorgenommen wurden, die sich an der neueren Rechtschreibung orientieren. Das Einfügen von Satzzeichen, die unkommentierte Auflösung abgekürzter Deklinationen, Artikel und Wortendungen sowie die Ergänzung von Konsonanten, die im Original durch einen Dopplungsstrich gekennzeichnet sind, sollten einen zügigen Lesefluss gewähren. Die Einträge sind in der originalen Reihenfolge belassen worden, auch wenn später Nachträge getätigt wurden.</p>
		<p>Diese Modifikationen scheinen von daher vertretbar, da das Faksimile unmittelbar neben der Transkription wiedergegeben wird bzw. damit verlinkt ist und somit einen direkten Vergleich sowie ggf. Korrekturen ermöglicht. Eigennamen wurden buchstabengetreu in die Edition übernommen, doch um – trotz verschiedener Schreibweisen – die Suche zu ermöglichen, wurde für das Personenregister eine einheitliche Schreibweise gewählt; dort sind jedoch auch auf die verschiedenen Schreibweisen aufgeführt.</p>
		<p>Textstellen und Beträge, die durchgestrichen sind, sind mit Durchstreichung aufgeführt, in der Datenbank scheinen diese Werte nicht auf. Wenn ganze Seiten im Original durchgestrichen sind, so wurde dies nicht weiter vermerkt, da auch dies über das Faksimile einsehbar ist.</p>
		<p>Das Original weist keine Paginierung auf: Die von uns vorgenommene Paginierung der Transkription beginnt jeweils ohne Vorsatz (der Vorsatz von Band I wurde bei der Restaurierung eingebunden). Die Leerseiten in Bd. III (S. 104 f., 1767, und S. 173, 1771) wurden in die Paginierung einbezogen.</p>
		<p>Die Abkürzungen und Schreibweisen von Sa (für Summa), pr (für per) und zus (für zusammen) wurden in der Transkription beibehalten, lediglich die Maßeinheiten x (Kreuzer) und f (Gulden) wurden aus Gründen der Lesbarkeit durch xr und fl ersetzt; auch hier kann jederzeit auf das Faksimile zurückgegriffen werden.</p>

		<h2>Hinweise zu Währung und zu Maßeinheiten</h2>
		<p>Als Recheneinheit fungierte im Untersuchungszeitraum der Gulden: Ein Gulden entsprach 60 Kreuzern, der Kreuzer zählte wiederum 4 Denare (Pfennige), zwei Kreuzer entsprachen einem Halbbatzen, vier Kreuzer einem Batzen. Zahlungen mit Dukaten wurden mit dem Kürzel  # gezeichnet und der Rechenwert (ca. 5 fl) verzeichnet. Eine Annäherung an die Kaufkraft der genannten Geldbeträge kann über folgenden Web-Rechner erfolgen: <a class="text-link" target="_blank" href="http://kaufkraftrechner.wohlstandsgeschichte.at/">http://kaufkraftrechner.wohlstandsgeschichte.at/</a>
		</p>
		<p>Bei den Längenmaßen wird häufig die Salzburger Elle genannt, die 79,2 cm fasste, während die Wiener Elle etwas kürzer war und nur 76,56 cm maß; der Salzburger Schuh (Fuß) maß 336 mm.</p>
		<p>Das Salzburger Pfund (ʉ) zählte 560 Gramm, d.h. ein Salzburger Zentner kam auf 56 Kilogramm. Das Salzburger Pfund fasste wiederum 32 Lot(h) a 17,5 Gramm; ein Quintel zählte 44 Gramm. Ein Schaff Weizen zählte 400 Pfund zu 8 Metzen, ein Schaff Korn dagegen 380 Pfund. Bei der Gerste zählte das Schaff 700 Pfund, beim Hafer 740 Pfund, und auf das Schaff entfielen 16 Metzen.</p>
		<p>Bei den Hohlmaßen fasste der Metzen 16 Maßl oder vier Viertel: Ein Viertel entsprach 1,57 Liter, ein Maßl fasste 0,39 Liter. Ein Emer Wein fasste 56,5 Liter und enthielt 36 Viertel (das Viertel zu 2 Kandl, das Kandl zu 2 Maßl, und das Maßl zu 2 Pfiff) 1 Yhrn (Wein) entsprach 62 Liter zu 40 Viertel, ein Emer Bier fasste 62 Liter, und dies entsprach 36 Vierteln.</p>
		<p>Der Salzburger Klafter hatte 6 Fuß; Brennholz hatte abhängig von der Scheitlänge meist 3 oder 4 Fuß. Holzkohle und Sand (sowie Salz) wurden in Fuder gemessen: Ein Fuder entsprach der Ladung eines zweispännigen Wagens, wobei ein Fuder 32 Eimer zu 56,5 Liter hatte (Zaisberger 1998, 181–183).</p>

		<h2>Hinweis zur Zitation der Ausgabenbücher und der Datenbank</h2>
		<p>Folgende Zitationsform wird empfohlen: </p>
		<p> - bei erstmaliger Nennung als Vollzitat: Ausgabenbücher Spängler Online, www.spaengler-haushaltsbuecher.at, bei darauffolgenden Nennungen als Kurzzitat: ASO</p>
		<p> - beim Verweis auf spezifische Einträge: ASO mit der Angabe des jeweiligen Bandes (als HB mit Nummer I bis IV bezeichnet) und dem Datum des Eintrages - z.B. ASO, HB IV, 28. 12. 1781. Ist kein Datum vorhanden, dann erfolgt die Beifügung "undat." und der Hinweis auf den Monat in Klammer, z.B. ASO, HB II, undat. (Jänner 1755)</p>
		<p> - beim Verweis auf eigene Auswertungen über die Datenbank der web-app: Eigene Berechnungen auf Basis ASO</p>

		<h2>Literatur</h2>
		<p>Gunda Barth-Scalmani, Der Handelsstand der Stadt Salzburg am Ende des 18. Jahrhunderts: Altständisches Bürgertum in Politik, Wirtschaft und Kultur, Diss. Phil. (masch.) Salzburg 1992.</p>
		<p>James Bear und Lucia Stanton (ed.), Jefferson’s Memorandum Books. Accounts, with Legal Records and Miscellany, 1767–1826, 2 Bde., Princeton–New Jersey 1997.</p>
		<p>Theodore Besterman (ed.), Voltaire’s Household Accounts 1760–1778, edited in facsimile, New York 1968.</p>
		<p>Karl Bräuer, Studien zur Geschichte der Lebenshaltung in Frankfurt a.M. während des 17. und 18. Jahrhunderts. Auf Grund des Nachlasses von Dr. Gottlieb Schnapper-Arndt, 2 Bde., Frankfurt/M. 1915.</p>
		<p>Friedrich Breitinger, Kurt Weinkamer und Gerda Dohle, Handwerker, Brauer, Wirte und Händler. Salzburgs gewerbliche Wirtschaft zur Mozartzeit, Salzburg 2009.</p>
		<p>John Brewer und Roy Porter (ed.), Consumption and the World of Goods, London 1993.</p>
		<p>Susanna Burghartz unter Mitarbeit von Sonia Calvi, Lukas Meili, Jonas Sagelsdorff und Georg Vogeler, Jahrrechnungen der Stadt Basel 1535 bis 1610, Basel–Graz 2015 (<a class="text-link" target="_blank" href="https://gams.uni-graz.at/context:srbas">https://gams.uni-graz.at/context:srbas</a>).</p>
		<p>Johann Caspar Goethe, Liber domesticus 1753–1779. Übertragen und kommentiert von Helmut Holtzhauer unter Mitarbeit von Irmgard Möller, 2 Bde., Bern–Frankfurt/M. 1973.</p>
		<p>Fred Händel und Axel Herrmann (Hg.), Das Hausbuch des Apothekers Michael Walburger 1562–1667. Quellenedition zur Kulturgeschichte eines bürgerlichen Hauswesens im 17. Jahrhundert, 5 Bde., Hof 1992.</p>
		<p>Barbara Kink, Adelige Lebenswelt in Bayern im 18. Jahrhundert: Die Tage- und Ausgabenbücher des Freiherrn Sebastian von Pemler von Hurlach und Leutstetten (1718–1772), München 2007.</p>
		<p>Björn R. Kommer, Lübeck 1787–1808: Die Haushaltungsbücher des Kaufmanns Jacob Behrens des Älteren, Lübeck 1989.</p>
		<p>Wilhelm Loose (Hg.), Anton Tuchers Haushaltsbuch, 1507 bis 1517, Stuttgart 1877.</p>
		<p>Gerd Nauhaus, Robert Schumann, Tagebücher, vol. III: Haushaltsbücher, Teil 1: 1837–1848 u. Teil 2: 1847–1856, Basel 1988.</p>
		<p>Virgil Redlich, Die Matrikel der Universität Salzburg 1639–1810, Salzburg 1933.</p>
		<p>Reinhold Reith et al., Das Verlassenschaftsinventar des Salzburger Tuch- und Seidenhändlers Franz Anton Spängler von 1784. Einführung und kommentierte Edition in Verbindung mit Andreas Zechner, Luisa Pichler, Doris Hörmann, Jürgen Wöhry und Florian Angerer, Salzburg 2015.</p>
		<p>Reinhold Reith, Luisa Pichler-Baumgartner, Georg Stöger und Andreas Zechner (Hg.), Haushalten und Konsumieren. Die Ausgabenbücher der Salzburger Kaufmannsfamilie Spängler von 1733 bis 1785, Salzburg 2016.</p>
		<p>Reinhold Reith und Georg Stöger, Exploring and Presenting Eighteenth-Century Private Consumption, in: Vierteljahrschrift für Sozial- und Wirtschaftsgeschichte 109 (2022), 72–86.</p>
		<p>Patrick Sahle, Digitale Edition, in: Fotis Jannidis (Hg.), Digital Humanities, Heidelberg 2017, 234-249.</p>
		<p>Roman Sandgruber, Die Anfänge der Konsumgesellschaft. Konsumgüterverbrauch, Lebensstandard und Alltagskultur in Österreich im 18. und 19. Jahrhundert, München 1982.</p>
		<p>Julia A. Schmidt-Funke (Hg.), Materielle Kultur und Konsum in der Frühen Neuzeit, Wien-Köln-Weimar 2019.</p>
		<p>Almut Spalding und Paul S. Spalding, The Account Books of the Reimarus Family of Hamburg, 1728– 1780. Turf and Tailors, Books and Beer, 2 Bde., Leiden 2015.</p>
		<p>Beate Spiegel, Adliger Alltag auf dem Land. Eine Hofmarksherrin, ihre Familie und ihre Untertanen in Tutzing um 1740, Münster 1997.</p>
		<p>Georg Vogeler, Digitale Edition von Wirtschafts- und Rechnungsbüchern, in: Gudrun Gleba und Niels Petersen (Hg.), Wirtschafts- und Rechnungsbücher des Mittelalters und der Frühen Neuzeit. Formen und Methoden der Rechnungslegung: Städte, Klöster, Kaufleute, Göttingen 2015, 307–328.</p>
		<p>Friederike Zaisberger, Geschichte Salzburgs, Wien–München 1998.</p>

		<h2>Abkürzungen</h2>
		<dl>
			<div>
				<dt>BS FA</dt>
				<dd>Bankhaus Spängler, Familienarchiv</dd>
			</div>
			<div>
				<dt>MGSL</dt>
				<dd>Mitteilungen der Gesellschaft für Salzburger Landeskunde</dd>
			</div>
			<div>
				<dt>SLA</dt>
				<dd>Salzburger Landesarchiv</dd>
			</div>
		</dl>
		<a name="colorCode"><h3>Legende der Schreiber</h3></a>
		<dl>
			<div>
				<dt class="writer writer-AES">AES</dt>
				<dd>Anna Elisabeth Spängler</dd>
			</div>
			<div>
				<dt class="writer writer-FAS">FAS</dt>
				<dd>Franz Anton Spängler</dd>
			</div>
			<div>
				<dt class="writer writer-FJC">FJC</dt>
				<dd>Franz Joseph Christian Spängler</dd>
			</div>
			<div>
				<dt class="writer writer-MFS">MFS</dt>
				<dd>Maria Franziska Christian Spängler</dd>
			</div>
			<div>
				<dt class="writer writer-MKS">MKS</dt>
				<dd>Maria Katharina Spängler</dd>
			</div>
			<div>
				<dt class="writer writer-MTS">MTS</dt>
				<dd>Maria Theresia Spängler</dd>
			</div>
		</dl>
	</div>
);
