import { JSX }		from "preact";

import { Messages }	from "@spaengler/frontend/locale";

// How many page links to show in the middle of lots of results
const siblings:number	= 2;
const shownPages:number	= (siblings * 2) + 1;

type PaginationProps = Readonly<{
	msg:				Messages,
	currentResultPage:	number,
	resultPages:		number,
	onChange:			(currentResultPage:number) => void,
}>;

export const Pagination = ({ currentResultPage, resultPages, onChange }:PaginationProps):JSX.Element => {
	// Pagination shows first and last page and five pages in between (2 siblings before and after currentResultPage):
	// 1 ... 3 4 [5] 6 7 ... 39
	// this function returns the first of these 5 pages
	const firstPage = (():number => {
		if ( resultPages <= shownPages + 1 + 1 ||
			currentResultPage === 1 + siblings + 1) {
			return 1;
		}
		if ( currentResultPage === resultPages - siblings - 1 ) {
			return resultPages - (siblings * 2);
		}
		const firstPage:number = currentResultPage - siblings > 1 ? currentResultPage - siblings : 1;
		const lastPage:number = firstPage + (siblings * 2);
		if ( lastPage <= resultPages ) {
			return firstPage;
		}
		return firstPage - (lastPage - resultPages);
	})();

	const handleClick = (currentResultPage:number) => (ev:Event):void => {
		ev.preventDefault();
		// used to re-search the database
		onChange(currentResultPage);
	};

	return (
		<div class="Pagination">
			{ firstPage > 1 + 1 &&
				<span>
					<button class='Pagination-button' onClick={handleClick(1)}>{1}</button>
					<span class='Pagination-spacer'>...</span>
				</span>
			}
			{ Array(resultPages <= (shownPages + 1 + 1) ? resultPages : shownPages).fill(0).map((_, index) =>
				<button
					class={`Pagination-button ${(currentResultPage === index + firstPage) ? "active" : ""}`}
					onClick={handleClick(index + firstPage)}
				>
					{index + firstPage}
				</button>)}
			{ firstPage < resultPages - shownPages && resultPages > shownPages + 1 + 1 &&
				<span>
					<span class='Pagination-spacer'>...</span>
				<button class='Pagination-button' onClick={handleClick(resultPages)}>
					{resultPages}
				</button>
			</span>}
		</div>
	);
};
