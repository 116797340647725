import { JSX }			from "preact";
import { useEffect }	from "preact/hooks";

import * as common		from "@spaengler/shared/CommonModel";

import { Toolbar }		from "@spaengler/frontend/pages/persons/Toolbar";
import { Display }		from "@spaengler/frontend/pages/persons/Display";

import { Messages }		from "@spaengler/frontend/locale";
import * as actions		from "@spaengler/frontend/actions";
import { setTitle }		from "@spaengler/frontend/util";

export type PersonsProps = Readonly<{
	persons:		ReadonlyArray<common.Person>|null,
	personsFilter:	string|null,
	firstletters:	ReadonlyArray<string>|null,
	msg:			Messages,
	lang:			string,
	path:			string,
}>;

type ExtendedProps = PersonsProps & Readonly<{ letter: string|undefined }>;

export const Persons = (props:PersonsProps):JSX.Element => {
	// preact-router does not allow us to include path parameters in our props
	const extendedProps	= props as ExtendedProps;
	const letter		= extendedProps.letter ?? null;

	// get first letters on mount
	useEffect(
		() => actions.getFirstletters(),
		[]
	);

	useEffect(
		() => setTitle(props.msg.personsTitle, props.msg),
		[ props.msg ]
	);

	// fetch persons then the letter changes
	useEffect(
		() => {
			actions.getPersons(letter === "" ? null : letter);
		},
		[ letter ]
	);

	return (
		<main class="Persons fadeIn main-book-asym-bg">
			<div class="book-asym">
				<div class="book-asym-inner">
					<h1>{props.msg.personsTitle}</h1>
					{ props.persons === null && <div>{props.msg.loading}</div> }
					{ props.persons !== null && props.firstletters !== null &&
						<div>
							{ props.firstletters !== null &&
								<Toolbar
									msg={props.msg}
									firstletters={props.firstletters}
								/>
							}
							{ props.persons !== null && props.personsFilter !== null &&
								<Display
									msg={props.msg}
									lang={props.lang}
									persons={props.persons}
									personsFilter={props.personsFilter}
								/>
							}
						</div>
					}
				</div>
			</div>
		</main>
	);
};
