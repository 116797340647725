import { JSX } from "preact";

import * as common		from "@spaengler/shared/CommonModel";

type BioProps = Readonly<{
	person:	common.Person,
	lang:	string,
}>;

export const Bio = (props:BioProps):JSX.Element => {
	const image = props.person.image;
	const name = props.person.name;
	const sources = props.person.sources;

	return (
		<div class="Bio">
			<h2 class="Bio-title">{name}</h2>
			{image !== null && image !== "" &&
				<figure>
					<img src={`/assets/p/${image}.jpg`} alt={`Portrait von ${name}`}/>
					<figcaption>{props.person.imageData}</figcaption>
				</figure>
			}
			<p>{props === undefined || props.lang === "de" ? props.person.bio : props.person.bio_en}</p>
			{sources !== "" &&
				<p class="Bio-sources">{sources}</p> }
		</div>
	);
};
