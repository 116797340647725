import * as common		from "@spaengler/shared/CommonModel";
import { Messages }		from "@spaengler/frontend/locale";

export const setTitle = (title:string, messages:Messages):void => {
	document.title = `${title} - ${messages.siteTitle1} ${messages.siteTitle2}`;
};

export const getQueryString = (options:common.DbOptions):string =>
	Object.entries(options)
		.map(([ k, v ]) => `${k}=${ v!== null ? encodeURIComponent(v) : ""}`)
		.join("&");
