import { JSX }		from "preact";
import { Link }		from "preact-router/match";

import { Model }	from "@spaengler/frontend/Model";

type FooterProps = Readonly<{
	model:	Model,
}>;

export const Footer = (props:FooterProps):JSX.Element => {
	const msg = props.model.msg;

	return (
		<footer class="Footer">
			<nav class="Footer-nav Footer-logos">
				<ul class="Footer-nav-ul">
					<li><a href="https://www.fwf.ac.at/"><img class="logo" alt="" src="/img/logos/fwf.svg"/><span class="sr-only">FWF</span></a></li>
					<li><a href="https://www.plus.ac.at"><img class="logo" alt="" src="/img/logos/uni-salzburg.svg"/><span class="sr-only">Universität Salzburg</span></a></li>
					<li><a href="https://www.stadt-salzburg.at/stadtarchiv"><img class="logo" alt="" src="/img/logos/stadtarchiv.svg"/><span class="sr-only">Stadtarchiv Salzburg</span></a></li>
					<li><a href="https://www.wissensstadt-salzburg.at"><img class="logo wissen" alt="" src="/img/logos/wissen.svg"/><span class="sr-only">Wissenstadt Stadt Salzburg</span></a></li>
				</ul>
			</nav>
			<nav class="Footer-nav Footer-imprint">
				<ul class="Footer-nav-ul">
					<li><Link activeClassName="active" href="/impressum">{msg.imprintTitle}</Link></li>
				</ul>
			</nav>
		</footer>
	);
};
