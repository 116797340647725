import { JSX }				from "preact";
import { useEffect }		from "preact/hooks";

import * as Arrays			from "ts-base/array";

import * as common			from "@spaengler/shared/CommonModel";

import { Messages }			from "@spaengler/frontend/locale";
import * as actions			from "@spaengler/frontend/actions";
import { getQueryString }	from "@spaengler/frontend/util";

type SearchProps = Readonly<{
	lang:		string,
	msg:		Messages,
	keywords:	common.Keywords,
	dbOptions:	common.DbOptions,
}>;

export const Search = (props:SearchProps):JSX.Element => {
	// reset form on unmount
	useEffect(
		() => () => {
			// TODO functional check this really still is necessary
			// TODO hack this is necessary to prevent an infinite render loop
			window.setTimeout(actions.resetDbForm, 100);
		},
		[]
	);

	const handleChange = (ev:JSX.TargetedEvent<HTMLInputElement, Event>):void => {
		// console.log("handleChange", ev.currentTarget.name, ev.currentTarget.value);
		actions.setDbFormVal(ev.currentTarget.name, ev.currentTarget.value);
		// console.debug("dbState", props.model);
	};

	const getKeywords = (name:string):ReadonlyArray<common.KeywordSplit> => {
		const lang		= props.lang;
		const keywords	= props.keywords;
		if (keywords === null)	return [];

		return	name === "keyword1"	?	lang === "en"
					?	keywords.keyword1_en
					:	keywords.keyword1
			:	name === "keyword2"	?	lang === "en"
					?	keywords.keyword2_en
					:	keywords.keyword2
			:	name === "recipient"	?	lang === "en"
					?	keywords.recipient_en
					:	keywords.recipient
			:	[];
	};

	const showSubSelect = (name:string, p2:string|null):boolean => {
		if (p2 === null || p2 === "")	return true;

		const keywords = getKeywords(name);
		const selection:common.KeywordSplit|null =
			keywords.find(it => it.name === p2) ?? null;

		return	selection === null || selection.name === ""
					? true :
				selection.children === null || selection.children.length === 0
					? false
					: true;
	};

	const handleSelectP1 = (ev:JSX.TargetedEvent<HTMLSelectElement, Event>, name:string):void => {
		const p1 = ev.currentTarget.value;
		actions.setDbFormVal(`${name}P1`, p1);
		if (p1 === "") {
			actions.setDbFormVal(`${name}P2`, "");
		}
	};

	const handleSelectP2 = (ev:JSX.TargetedEvent<HTMLSelectElement, Event>, name:string):void => {
		const p2 = ev.currentTarget.value;
		actions.setDbFormVal(`${name}P2`, p2);
	};

	const createP1Items = (name:string, p2:string|null):ReadonlyArray<JSX.Element> => {
		const keywords = getKeywords(name);
		if (p2 === null || p2 === "") {
			return getKeywords(name).map(it =>
				<option value={it.name}>{it.name}</option>
			);
		}
		const parents:ReadonlyArray<common.KeywordSplit> =
			keywords.filter(it => it.children.includes(p2));
		return parents.map(it =>
			<option value={it.name}>{it.name}</option>
		);
	};

	const createP2Items = (name:string, p1:string|null):ReadonlyArray<JSX.Element> => {
		const keywords = getKeywords(name);
		const selection:common.KeywordSplit|null =
			keywords.find(it => it.name === p1) ?? null;

		if (p1 === null || p1 === "" || selection === null) {
			const children:ReadonlyArray<string>		= Arrays.flatMap(keywords)(it => it.children);
			const uniqueChildren:ReadonlyArray<string>	= children.filter((item, index) => children.indexOf(item) === index);
			const sortedChildren:ReadonlyArray<string>	= Arrays.sortBuiltin(uniqueChildren);
			return sortedChildren.map(it =>
				<option value={it}>{it}</option>
			);
		}
		return selection.children.map(it =>
			<option value={it}>{it}</option>
		);
	};

	const reset = ():void => {
		// console.debug("reset");
		actions.resetDbForm();
	};

	const getDownloadLink = (dbOptions:common.DbOptions):string => {
		const query = getQueryString(dbOptions);
		return `/api/db?${query}&download=true&lang=${props.lang}`;
	};

	const handleSubmit = (ev:JSX.TargetedEvent<HTMLFormElement, Event>):void => {
		ev.preventDefault();
		// console.debug("submit", data, ev);
		actions.getDb(props.dbOptions, props.lang);
	};

	const msg		= props.msg;
	const dbOptions	= props.dbOptions;

	return (
		<form onSubmit={handleSubmit} class="Search form fadeIn">
			<div class="form-unit">
				<label for="item" class="form-label form-unit-col1">{msg.itemLabel}</label>
				<input
					onChange={handleChange}
					value={dbOptions.item ?? ""}
					name="item"
					id="item"
					type="text"
					class="form-input-text form-unit-col2"
				/>
			</div>
			<div class="form-unit">
				<label for="keyword1" class="form-label form-unit-col1">{msg.keyword1}</label>
				<div class="form-unit-col2 flex">
					<select
						value={dbOptions.keyword1P1 ?? ""}
						class="form-select half"
						name="keyword1P1"
						onChange={ev => handleSelectP1(ev, "keyword1")}
					>
						<option></option>
						{createP1Items("keyword1", dbOptions.keyword1P2)}
					</select>
					{	showSubSelect("keyword1", dbOptions.keyword1P1)
						?	<select
								value={dbOptions.keyword1P2 ?? ""}
								class="form-select ml half"
								name="keyword1P2"
								onChange={ev => handleSelectP2(ev, "keyword1")}
							>
								<option></option>
								{createP2Items("keyword1", dbOptions.keyword1P1)}
							</select>
						:	<span></span>
					}
				</div>
			</div>
			<div class="form-unit">
				<label for="keyword2" class="form-label form-unit-col1">{msg.keyword2}</label>
				<div class="form-unit-col2 flex">
					<select
						value={dbOptions.keyword2P1 ?? ""}
						class="form-select half"
						name="keyword2P1"
						onChange={ev => handleSelectP1(ev, "keyword2")}
					>
						<option></option>
						{createP1Items("keyword2", dbOptions.keyword2P2)}
					</select>
					{	showSubSelect("keyword2", dbOptions.keyword2P1)
						?	<select
								value={dbOptions.keyword2P2 ?? ""}
								class="form-select ml half"
								name="keyword2P2"
								onChange={ev => handleSelectP2(ev, "keyword2")}
							>
								<option></option>
								{createP2Items("keyword2", dbOptions.keyword2P1)}
							</select>
						:	<span></span>
					}
				</div>
			</div>
			<div class="form-unit">
				<label for="recipient" class="form-label form-unit-col1">{msg.recipient}</label>
				<div class="form-unit-col2 flex">
					<select
						value={dbOptions.recipientP1 ?? ""}
						class="form-select half"
						name="recipientP1"
						onChange={ev => handleSelectP1(ev, "recipient")}
					>
						<option></option>
						{createP1Items("recipient", dbOptions.recipientP2)}
					</select>
					{	showSubSelect("recipient", dbOptions.recipientP1)
						?	<select
								value={dbOptions.recipientP2 ?? ""}
								class="form-select ml half"
								name="recipientP2"
								onChange={ev => handleSelectP2(ev, "recipient")}
							>
								<option></option>
								{createP2Items("recipient", dbOptions.recipientP1)}
							</select>
						:	<span></span>
					}
				</div>
			</div>
			<div class="form-unit">
				<label for="persons" class="form-label form-unit-col1">{msg.persons}</label>
				<input
					onChange={handleChange}
					value={dbOptions.persons ?? ""}
					name="persons"
					id="persons"
					type="text"
					class="form-input-text form-unit-col2"
				/>
			</div>
			<div class="form-unit">
				<span class="form-label form-unit-col1" aria-hidden="true">{msg.date}</span>
				<div class="form-unit-col2">
					<label for="startDate" class="form-label fw"><span class="sr-only">{msg.date}</span> {msg.from}</label>
					<input
						onChange={handleChange}
						value={dbOptions.startDate ?? ""}
						name="startDate"
						id="startDate"
						type="text"
						placeholder={msg.placeholderDate}
						pattern="\d{4}-\d{1,2}-\d{1,2}"
						class="form-input-text date ml"
					/>

					<label for="endDate" class="form-label ml fw"><span class="sr-only">{msg.date}</span> {msg.to}</label>
					<input
						onChange={handleChange}
						value={dbOptions.endDate ?? ""}
						name="endDate"
						id="endDate"
						type="text"
						placeholder={msg.placeholderDate}
						pattern="\d{4}-\d{1,2}-\d{1,2}"
						class="form-input-text date ml"
					/>
				</div>
			</div>
			<div class="form-unit">
				<span class="form-label form-unit-col1" aria-hidden="true">{msg.value}</span>
				<div class="form-unit-col2">
					<label for="min" class="form-label fw"><span class="sr-only">{msg.value}</span> {msg.min}</label>
					<input
						onChange={handleChange}
						value={dbOptions.min ?? ""}
						name="min"
						id="min"
						type="text"
						placeholder="0.00"
						pattern="\d+(\.\d{1,2})?"
						class="form-input-text value ml"
					/>
					<label for="max" class="form-label ml fw">{msg.max}</label>
					<input
						onChange={handleChange}
						value={dbOptions.max ?? ""}
						name="max"
						id="max"
						type="text"
						placeholder="0.00"
						pattern="\d+(\.\d{1,2})?"
						class="form-input-text value ml"
					/>
				</div>
			</div>
			<div class="form-unit">
				<label for="page" class="form-label form-unit-col1">{msg.book}<span aria-hidden="true">/ {msg.page}</span></label>
				<div class="form-unit-col2">
					<input
						onChange={handleChange}
						value={dbOptions.book ?? ""}
						name="book"
						id="book"
						type="text"
						class="form-input-text number-l"
					/>
					<label for="page" class="form-label"><span class="sr-only">{msg.page}</span></label>
					<span aria-hidden="true"> / </span>
					<input
						onChange={handleChange}
						value={dbOptions.page ?? ""}
						name="page"
						id="page"
						type="text"
						class="form-input-text number-l"
					/>
				</div>
			</div>
			<div class="form-unit">
				<div class="form-unit-col1"></div>
				<div class=" form-unit-col2 form-button-spreader">
					<button onClick={reset} type="button" class="text-link">{msg.reset}</button>
					<a href={getDownloadLink(dbOptions)} class="text-link" download="Export.xlsx" target="_blank">Download</a>
					<button type="submit" class="form-button solo">{msg.search}</button>
				</div>
			</div>
			<hr class="form-separator"/>
		</form>
	);
};
