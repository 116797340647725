import { JSX }				from "preact";
import { useState, useRef }	from "preact/hooks";

import * as common			from "@spaengler/shared/CommonModel";

import { Bio }				from "@spaengler/frontend/components/Bio";

type TogglePopupProps = Readonly<{
	personlist:			ReadonlyArray<common.Person>,
	personsReference:	common.PersonsReference,
	lang:				string,
}>;

export const TogglePopup = (props:TogglePopupProps):JSX.Element => {
	const [ visible, setVisible ] = useState<boolean>(false);
	const closeButton = useRef<HTMLButtonElement>(null);
	const linkButton = useRef<HTMLButtonElement>(null);

	const toggle = ():void => {
		setVisible(!visible);

		const button = visible ? closeButton.current : linkButton.current;
		if (button !== null) {
			setTimeout(() => button.focus(), 100);
		}
	};

	const close = ():void => {
		setVisible(false);

		const button = linkButton.current;
		if (button !== null) {
			setTimeout(() => button.focus(), 100);
		}
	};

	return (
		<span>
			<button ref={linkButton} class="text-link" onClick={toggle}>{props.personsReference.displayText}</button>
			<div class={visible ? "PopupBio-backdrop fadeInFast" : "hidden"} onTransitionEnd={close}>
				<div class="PopupBio-inner">
					<button ref={closeButton} onClick={toggle} class="PopupBio-close">
						<span class="fa fa-close" aria-hidden="true"></span>
						<span class="sr-only">Close</span>
					</button>
					{ props.personsReference.targets.map((target) =>
						props.personlist.filter(person => person.name === target).map((person) =>
							<Bio person={person} lang={props.lang}/>
						)
					)}
				</div>
			</div>
		</span>
	);
};
