import { JSX }			from "preact";
import { Link }			from "preact-router/match";

import { Messages }		from "@spaengler/frontend/locale";

type ToolbarProps = Readonly<{
	msg:			Messages,
	firstletters:	ReadonlyArray<string>,
}>;

export const Toolbar = (props:ToolbarProps):JSX.Element =>
	<div class="Persons-toolbar fadeIn">
		<ul class="Persons-toolbar-links">
			{alphabet.map(letter =>
				props.firstletters.includes(letter)
				?	<li><Link class="form-button" href={"/persons/" + letter}>{letter}</Link></li>
				:	<li><Link class="form-button disabled" disabled>{letter}</Link></li>
			)}
		</ul>
		<Link class="Persons-toolbar-allPersons-link text-link" href="/persons">{props.msg.allPersons}</Link>
	</div>;

const alphabet = [
	"A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
	"N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
];
