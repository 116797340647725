import { JSX } from "preact";

export const DeImpressum = ():JSX.Element => (
	<div>
		<h1>Impressum</h1>

		<p>Die Ausgabenbücher der Salzburger Kaufmannsfamilie Spängler von 1733 bis 1785 – digital</p>

		<p>Herausgegeben von Reinhold Reith und Georg Stöger unter Mitarbeit von Luisa Pichler-Baumgartner, Maria Johanna Falkner und Katharina Scharf, Salzburg 2019</p>

		<p>Gefördert durch den Fonds zur Förderung der wissenschaftlichen Forschung (FWF) PUD 10</p>

		<h2>Projektleitung und Konzeption</h2>

		<p>Reinhold Reith und Georg Stöger, Universität Salzburg</p>

		<h2>Veröffentlichung</h2>

		<p>Stadtarchiv bzw. Stadt Salzburg<br/>
		Permalink: <a class="text-link" href="https://www.spaengler-haushaltsbuecher.at">www.spaengler-haushaltsbuecher.at
		</a><br/>
		Erstveröffentlichung: 1. Juni 2020 (zuletzt geändert: 11. Juli 2024)</p>

		<h2>Mitarbeiter/innen</h2>

		<p>Transkription: Reinhold Reith, Luisa Pichler-Baumgartner, Maria Falkner. Die Transkription wurde gefördert durch die Wiesmüller Privatstiftung und die Stiftungs- und Förderungsgesellschaft der Universität Salzburg.<br/>
		Schlagworte: Katharina Scharf<br/>
		Digitalisate: Stadtarchiv Salzburg, erstellt unter Leitung von Dr. Peter F. Kramml</p>

		<h2>Technische Umsetzung und Design</h2>

		<p>v3 consulting GmbH, München<br/>
		<a class="text-link" href="https://www.v3consulting.com/">www.v3consulting.com</a>
		</p>

		<h2>Kontakt und Information<br/> gemäß § 5 E-Commerce Gesetz</h2>

		<p>Die Informationen und Dienste auf dieser Seite werden zur Verfügung gestellt von</p>

		<p class="highlight-p">Reinhold Reith u. Georg Stöger<br/>
		Fachbereich Geschichte – Wirtschafts- Sozial- und Umweltgeschichte<br/>
		Universität Salzburg<br/>
		Rudolfskai 42<br/>
		A-5020 Salzburg – Österreich<br/>
		E-Mail: <a class="text-link" href="mailto:reinhold.reith@sbg.ac.at">reinhold.reith@sbg.ac.at</a> u. <a class="text-link" href="mailto:georg.stoeger@sbg.ac.at">georg.stoeger@sbg.ac.at</a>
		</p>
	</div>
);
