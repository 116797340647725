import { JSX }			from "preact";
import { useEffect }	from "preact/hooks";

import { setTitle }		from "@spaengler/frontend/util";
import { Messages }		from "@spaengler/frontend/locale";
import { DeImpressum }	from "@spaengler/frontend/pages/impressum/DeImpressum";
import { EnImpressum }	from "@spaengler/frontend/pages/impressum/EnImpressum";

type ImpressumProps = Readonly<{
	msg:	Messages,
	lang:	string,
	path:	string,
}>;

export const Impressum = (props:ImpressumProps):JSX.Element => {
	useEffect(
		() => {
			setTitle(props.msg.imprintTitle, props.msg);
		},
		[ props.msg ]
	);

	return (
		<main class="main-book-asym-bg">
			<div class="book-asym">
				<div class="book-asym-inner">
					{props.lang === "de" ? <DeImpressum/> : <EnImpressum/> }
				</div>
			</div>
		</main>
	);
};
