import { Upd, connect }		from "@spaengler/frontend/connect";
import { Model }			from "@spaengler/frontend/Model";
import { App }				from "@spaengler/frontend/App";
import * as actions			from "@spaengler/frontend/actions";

// console.log("lang",		navigator.language);
// console.log("langs",	navigator.languages);

const lang = navigator.language.startsWith("de") ? "de" : "en";

const initialModel = Model.initial(lang);

const refresh:Upd<Model> = connect(
	document.querySelector("#app") as HTMLElement,
	(model) => {
		return <App model={model}/>;
	},
	initialModel
);

actions.connectRefresh(refresh);
actions.boot();
