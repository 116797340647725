import { JSX }			from "preact";
import { useEffect }	from "preact/hooks";
import { Link }			from "preact-router/match";

import { setTitle }		from "@spaengler/frontend/util";
import { Messages }		from "@spaengler/frontend/locale";

type StartProps = Readonly<{
	msg:	Messages,
	path:	string,
}>;

export const Start = (props:StartProps):JSX.Element => {
	useEffect(
		() => {
			setTitle("", props.msg);
		},
		[ props.msg ]
	);

	const msg = props.msg;
	return (
		<main class="Start main-book-asym-bg">
			<div class="book-asym">
				<div class="book-asym-inner">
					<h1>
						<span>{msg.siteTitle1} {msg.siteTitle2}</span>
						<span>{msg.siteSubtitle}</span>
					</h1>
					<div class="Start-intro">
					<p>{msg.introduction}</p>
					<p class="center">
						<Link class="text-link" activeClassName="active" href="/introduction">
							{msg.introductionTitle}
						</Link>
					</p>
					</div>
					<hr class="form-separator"/>
					<div class="form-button-wrapper">
						<Link class="form-primary-button" activeClassName="active" href="/transcription/1/3">
							{msg.transcriptionTitle}
						</Link>
						<Link class="form-primary-button" activeClassName="active" href="/db">
							{msg.dbTitle}
						</Link>
					</div>
				</div>
			</div>
		</main>
	);
};
