import { JSX }		from "preact";
import { useState }	from "preact/hooks";
import { Link }		from "preact-router/match";

import { Person }	from "@spaengler/shared/CommonModel";

import { Messages }	from "@spaengler/frontend/locale";
import * as actions from "@spaengler/frontend/actions";

type DisplayProps = Readonly<{
	persons:		ReadonlyArray<Person>,
	personsFilter:	string,
	msg:			Messages,
	lang:			string,
}>;

export const Display = (props:DisplayProps):JSX.Element => {
	const [ fullsize, setFullsize ] = useState<number>(-1);

	return (
		<table class="Persons-table" id="persons-table fadeIn">
			<tr>
				<td class="table-right table-padding">
					<label class="form-label" for="Persons-filter">{props.msg.filterLabel}:</label>
				</td>
				<td class="table-padding">
					<input
						id="Persons-filter"
						type="text"
						class="form-input-text name"
						onInput={ev => actions.setFilter(ev.currentTarget.value)}
						value={props.personsFilter ?? ""}
					/>
				</td>
			</tr>
			{props.persons.filter(it =>
				it.name.toLowerCase()
					.includes(props.personsFilter.toLowerCase())
				).map((it, index) =>
					<tr>
						<th class="Persons-name table-right">
							<Link class="text-link" activeClassName="active" href={`/db/?persons=${it.name}`}>{it.name}</Link>
						</th>
						<td class="Persons-bio">
							{ it.image !== null && it.image !== "" &&
								<div
									className={`Persons-preview ${fullsize === index ? "fullsize" : ""}`}
									onClick={ () => setFullsize(fullsize !== index ? index : -1 ) }
								>
									<img
										class="Persons-img"
										src={`/assets/p/${it.image}.jpg`}
										alt={`Portrait ${it.name}`}
									/>
									<div class="Persons-img-data">{it.imageData}</div>
								</div>
							}
							{props.lang === "de" ? it.bio : it.bio_en}
							{it.sources !== "" &&
								<p class="Persons-sources">{it.sources}</p>
							}
						</td>
					</tr>
			)}
		</table>
	);
};
