import { JSX }			from "preact";

import * as common		from "@spaengler/shared/CommonModel";

import { TogglePopup }	from "@spaengler/frontend/components/TogglePopup";
import { Messages }		from "@spaengler/frontend/locale";

type PopupBioProps = Readonly<{
	msg:		Messages,
	personlist:	ReadonlyArray<common.Person>,
	parsedItem:	common.ParsedItem,
	indexRow:	number|null,
	lang:		string,
}>;

export const PopupBio = (props:PopupBioProps):JSX.Element => {
	const displayTexte								= props.parsedItem.personsReferences.map(x => x.displayText);
	const namesInPersonlist:ReadonlyArray<string>	= props.personlist.map(person => person.name);

	return (
		<div class="PopupBio">
			<div class="PopupBio-rowtext">
				{
					props.parsedItem.fragments.map(fragment => {
						const fragmentIndex = displayTexte.indexOf(fragment);
						if (fragmentIndex > -1) {
							const personsReference = props.parsedItem.personsReferences[fragmentIndex];
							// check if all targets occur in person list
							const missingPersons:ReadonlyArray<string> = personsReference.targets.filter(target => !namesInPersonlist.includes(target));
							if (missingPersons.length > 0) {
								console.log(`PLEASE FIX: "${missingPersons.join("; ")}" missing in person list`);
								return <span>{fragment}</span>;
							}
							return <TogglePopup personsReference={personsReference} personlist={props.personlist} lang={props.lang}/>;
						}
						else {
							return <span>{fragment}</span>;
						}
					})
				}
			</div>
		</div>
	);
};
