import { JSX }				from "preact";
import { Router }			from "preact-router";
import { Model }			from "@spaengler/frontend/Model";
import { Start }			from "@spaengler/frontend/pages/start/Start";
import { Transcription }	from "@spaengler/frontend/pages/transcription/Transcription";
import { Db }				from "@spaengler/frontend/pages/db/Db";
import { Persons }			from "@spaengler/frontend/pages/persons/Persons";
import { Impressum }		from "@spaengler/frontend/pages/impressum/Impressum";
import { Introduction }		from "@spaengler/frontend/pages/introduction/Introduction";
import { Header }			from "@spaengler/frontend/components/Header";
import { Footer }			from "@spaengler/frontend/components/Footer";

type AppProps = Readonly<{
	model:	Model,
}>;

export const App = (props:AppProps):JSX.Element => (
	<div class="App">
		<Header	model={props.model}/>
		<Router>
			<Start
				path="/" msg={props.model.msg}
			/>
			<Transcription
				path="/transcription/:book/:page"
				lang={props.model.lang}
				msg={props.model.msg}
				screen={props.model.screen}
				statistics={props.model.statistics}
				spinner={props.model.spinner}
			/>
			<Db
				path="/db"
				lang={props.model.lang}
				msg={props.model.msg}
				keywords={props.model.keywords}
				dbOptions={props.model.dbOptions}
				db={props.model.db}
			/>
			<Persons
				path="/persons/:letter?"
				lang={props.model.lang}
				msg={props.model.msg}
				persons={props.model.persons}
				personsFilter={props.model.personsFilter}
				firstletters={props.model.firstletters}
			/>
			<Introduction
				path="/introduction"
				msg={props.model.msg}
				lang={props.model.lang}
			/>
			<Impressum
				path="/impressum"
				msg={props.model.msg}
				lang={props.model.lang}
			/>
		</Router>
		<Footer model={props.model}/>
	</div>
);
