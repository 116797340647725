import { JSX }		from "preact";
import { Link }		from "preact-router/match";

import * as actions from "@spaengler/frontend/actions";
import { Model }	from "@spaengler/frontend/Model";

type HeaderProps = Readonly<{
	model:	Model,
}>;

export const Header = (props:HeaderProps):JSX.Element => {
	const msg = props.model.msg;
	return (
		<header class="Header">
			<Link class="Header-left" activeClassName="active" href="/">
				<img class="Header-logo" src="/img/logo-spaengler.png" alt=""/>
				<div class="Header-title">{msg.siteTitle1}<br/>{msg.siteTitle2}</div>
			</Link>
			<div class="Header-filler"></div>
			<nav class="Header-nav">
				<ul class="Header-nav-ul">
					<li><Link activeClassName="active" href="/introduction">{msg.introductionTitle}</Link></li>
					<li><Link activeClassName="active" href="/transcription/1/3">{msg.transcriptionTitle}</Link></li>
					<li><Link activeClassName="active" href="/db">{msg.dbTitle}</Link></li>
					<li><Link activeClassName="active" href="/persons/A">{msg.personsTitle}</Link></li>
				</ul>
			</nav>
			<button class="Header-language-switch" onClick={() => actions.setLang(msg.otherLangCode)}>{msg.otherLang}</button>
		</header>
	);
};
