import { JSX }			from "preact";
import { Link }			from "preact-router/match";

import * as common		from "@spaengler/shared/CommonModel";

import * as actions		from "@spaengler/frontend/actions";
import { PopupBio }		from "@spaengler/frontend/components/PopupBio";
import { Pagination }	from "@spaengler/frontend/components/Pagination";
import { Messages }		from "@spaengler/frontend/locale";

type ResultsProps = Readonly<{
	lang:			string,
	msg:			Messages,
	results:		number,
	resultPages:	number,
	offset:			number,
	data:			ReadonlyArray<common.SearchData>,
	personlist:		ReadonlyArray<common.Person>,
	dbOptions:		common.DbOptions,
}>;

export const Results = (props:ResultsProps):JSX.Element => {
	const changeSortOrder = (sortBy:string) => (ev:Event):void => {
		ev.preventDefault();
		const dbOptions	= actions.changeSort(sortBy)(props.dbOptions);
		actions.setDbForm(dbOptions);
		actions.getDb(dbOptions, props.lang);
	};

	const changeCurrentResultPage = (currentResultPage:number):void => {
		const dbOptions	= actions.changeCurrentResultPageData(currentResultPage)(props.dbOptions);
		actions.setDbForm(dbOptions);
		actions.getDb(dbOptions, props.lang);
	};

	const getClass = (column:string):string =>
		column === props.dbOptions.sortBy
		?	`Db-sort-by active ${props.dbOptions.sortMode}`
		:	"Db-sort-by";

	const msg = props.msg;
	return	(
		<div class="Results">
			{ props.results > 0
				?	<table class="Db-table fadeIn">
						<thead>
							<tr>
								<th colSpan={3} class="Db-table-th1">{msg.place}</th>
								<th colSpan={5} class="Db-table-th2">{msg.transcription}</th>
								<th colSpan={7} class="Db-table-th3">{msg.meta}</th>
							</tr>
							<tr>
								<th class="Db-table-num-l" colSpan={2}>
									<button class={getClass("row_number")} onClick={changeSortOrder("row_number")}>
										{msg.book}/{msg.page}
									</button>
								</th>
								<th class="Db-table-date">
									<button class={getClass("date_combined")} onClick={changeSortOrder("date_combined")}>
										{msg.date}
									</button>
								</th>
								<th class="Db-table-col1">{msg.day}</th>
								<th class="Db-table-col2">{msg.month}</th>
								<th class="Db-table-col3">{msg.item}</th>
								<th class="Db-table-col4">{msg.gulden}</th>
								<th class="Db-table-col5">{msg.kreuzer}</th>
								<th class="Db-table-num-l">
									<button class={getClass("value_calculable")} onClick={changeSortOrder("value_calculable")}>
										{msg.value}
									</button>
								</th>
								<th class="Db-table-abbr">
									<button class={getClass("writer")} onClick={changeSortOrder("writer")}>
										{msg.writer}
									</button>
								</th>
								<th class="Db-table-txt">
									<button class={getClass("persons")} onClick={changeSortOrder("persons")}>
										{msg.persons}
									</button>
								</th>
								<th class="Db-table-txt">
									<button class={getClass("places")} onClick={changeSortOrder("places")}>
										{msg.places}
									</button>
								</th>
								<th class="Db-table-txt">
									<button class={getClass("keyword_1")} onClick={changeSortOrder("keyword_1")}>
										{msg.keyword1}
									</button>
								</th>
								<th class="Db-table-txt">
									<button class={getClass("keyword_2")} onClick={changeSortOrder("keyword_2")}>
										{msg.keyword2}
									</button>
								</th>
								<th class="Db-table-txt">
									<button class={getClass("recipient")} onClick={changeSortOrder("recipient")}>
										{msg.recipient}
									</button>
								</th>
							</tr>
						</thead>
						{ props.results > 0 &&
							<tbody>
								{props.data.map((it:common.SearchData, index) =>
									<tr class="Db-row">
										<td class="Db-table-num">{it.bookNumber}</td>
										<td class="Db-table-num"><Link activeClassName="active" href={`/transcription/${it.bookNumber}/${it.pageNumber}`}>{it.pageNumber}</Link></td>
										<td class="Db-table-date">{it.dateCombined}</td>
										<td class="Db-table-col1 table-right Db-table-ed">{it.ed.day}</td>
										<td class="Db-table-col2 Db-table-ed">{it.ed.month}</td>
										<td class="Db-table-col3 Db-table-ed">{
											it.ed.parsedItem !== null && props.personlist !== null
											?	<PopupBio
													parsedItem={it.ed.parsedItem}
													personlist={props.personlist}
													indexRow={index}
													msg={msg}
													lang={props.lang}
												/>
											: it.ed.item }
										</td>
										<td class="Db-table-col4 table-right Db-table-ed">{it.ed.gulden}</td>
										<td class="Db-table-col5 table-nw Db-table-ed">{it.ed.kreuzer} {it.ed.rest}</td>
										<td class="Db-table-num-l">{it.valueCalculable}</td>
										<td class="Db-table-abbr">{it.writer}</td>
										<td class="Db-table-txt">{it.persons}</td>
										<td class="Db-table-txt">{it.places}</td>
										<td class="Db-table-txt">{it.keyword1}</td>
										<td class="Db-table-txt">{it.keyword2}</td>
										<td class="Db-table-txt">{it.recipient}</td>
									</tr>
								)}
							</tbody>
						}
						<tfoot>
							<tr>
								<td colSpan={15}>
									<div class="Db-results">
										<div class="Db-pagination">
											{
												// TODO clarify how and when props.dbOptions.currentResultPage can be null
												props.resultPages > 1
												?	<Pagination
														currentResultPage={props.dbOptions.currentResultPage ?? 1}
														resultPages={props.resultPages}
														msg={msg}
														onChange={changeCurrentResultPage}
													/>
												:	<span></span>
											}
										</div>
										<span class="Db-results-count">
											{ (props.results > 0 ? props.offset + 1 : 0) } - { (props.offset + props.data.length) } {msg.of} {props.results} {msg.results}
										</span>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				:	<div class="Db-no-results fadeIn"><span>{msg.noResults}</span></div>
			}
		</div>
	);
};
