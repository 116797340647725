import { JSX }			from "preact";
import { useEffect }	from "preact/hooks";

import * as common		from "@spaengler/shared/CommonModel";

import { Messages }		from "@spaengler/frontend/locale";
import { setTitle }		from "@spaengler/frontend/util";

import { Display }		from "@spaengler/frontend/pages/transcription/Display";
import { Toolbar }		from "@spaengler/frontend/pages/transcription/Toolbar";
import * as actions		from "@spaengler/frontend/actions";

type TranscriptionProps = Readonly<{
	screen:		common.EditionScreen|null,
	statistics:	common.Statistics|null,
	spinner:	boolean,
	msg:		Messages,
	lang:		string,
	path:		string,
}>;

type ExtendedProps = TranscriptionProps & Readonly<{ book: string, page: string }>;

export const Transcription = (props:TranscriptionProps):JSX.Element => {
	// preact-router does not allow us to include path parameters in our props
	const extendedProps	= props as ExtendedProps;

	useEffect(
		() => setTitle(props.msg.transcriptionTitle, props.msg),
		[ props.msg ]
	);

	useEffect(
		() => {
			// NOTE these can _not_ be undefined, but they can still be empty which isNaN catches because "" in parseInt fails
			const newBook = intOrNull(extendedProps.book) ?? props.screen?.bookNumber ?? 1;
			const newPage = intOrNull(extendedProps.page) ?? props.screen?.pageNumber ?? 3;

			// TODO functional do we need this? probably yes because getPage does an immediate refresh
			// BUT preact changed in this so maybe a window.setTimeout would be better
			void Promise.resolve().then(() => actions.getPage(newBook, newPage));
		},
		[
			extendedProps.book,
			extendedProps.page,
			// cannot use props.model.screen here because it's set to a new object in actions.getPage
			props.screen?.bookNumber,
			props.screen?.pageNumber,
		]
	);

	return (
		<main class="transcription main-book-bg">
			<div class="book-middle">
				<div class="book-middle-inner">
					<h1 class="sr-only">{props.msg.transcriptionTitle}</h1>
					{ props.screen === null &&
						<div class="loading">{props.msg.loading}</div> }
					{ props.screen !== null && props.statistics !== null &&
						<div>
							<Toolbar
								position="top"
								msg={props.msg}
								statistics={props.statistics}
								screen={props.screen}
							/>
							{ screen !== null &&
								<Display
									msg={props.msg}
									screen={props.screen}
									spinner={props.spinner}
									lang={props.lang}
								/>
							}
							<Toolbar
								position="bottom"
								msg={props.msg}
								statistics={props.statistics}
								screen={props.screen}
							/>
						</div>
					}
				</div>
			</div>
		</main>
	);
};

const intOrNull	= (it:string):number|null	=> {
	const out	= parseInt(it);
	return isNaN(out) ? null : out;
};
