import { JSX }			from "preact";
import { useEffect }	from "preact/hooks";

import { DeIntro }		from "@spaengler/frontend/pages/introduction/DeIntro";
import { EnIntro }		from "@spaengler/frontend/pages/introduction/EnIntro";

import { setTitle }		from "@spaengler/frontend/util";
import { Messages }		from "@spaengler/frontend/locale";

type IntroductionProps = Readonly<{
	msg:	Messages,
	lang:	string,
	path:	string,
}>;

export const Introduction = (props:IntroductionProps):JSX.Element => {
	useEffect(
		() => {
			setTitle(props.msg.introductionTitle, props.msg);
		},
		[ props.msg ]
	);

	/*
	the Db page links to the #aboutDb header , but this does not work in a preact app:
	at the time where the browser recognizes the urt change, the page content is
	not rendered yet - so the #aboutDb element does not exist and therefore the browser
	cannot scroll it into view.

	without target="blank" which would not work either, because preact-router
	only changes the window state in code which the browser ignores for this purpose.
	even for plain <a> tags, preact router takes control over clicks so these do not work either
	*/
	useEffect(
		() => {
			if (window.location.hash !== "#aboutDb")	return;
			const aboutDb	= document.getElementById("aboutDb");
			if (aboutDb === null)						return;
			aboutDb.scrollIntoView();
		},
		[]
	);

	return (
		<main class="main-book-asym-bg">
			<div class="book-asym">
				<div class="book-asym-inner">
					{props.lang === "de" ? <DeIntro/> : <EnIntro/> }
				</div>
			</div>
		</main>
	);
};
