import { JSX }			from "preact";
import { Link }			from "preact-router/match";
import { useEffect }	from "preact/hooks";

import * as common		from "@spaengler/shared/CommonModel";

import { Messages }		from "@spaengler/frontend/locale";
import * as actions		from "@spaengler/frontend/actions";
import { setTitle }		from "@spaengler/frontend/util";

import { Search	}		from "@spaengler/frontend/pages/db/Search";
import { Results }		from "@spaengler/frontend/pages/db/Results";

type DbProps = Readonly<{
	lang:		string,
	msg:		Messages,
	keywords:	common.Keywords|null,
	dbOptions:	common.DbOptions,
	db:			common.SearchScreen|null,
	path:		string,
}>;

// these parameters are not from the path, but from query parameters as used in Toolbar.tsx and Display.tsx
type ExtendedProps = DbProps & Readonly<{ book: string|undefined, page: string|undefined, persons: string|undefined }>;

export const Db = (props:DbProps):JSX.Element => {
	// preact-router does not allow us to include path parameters in our props
	const extendedProps	= props as ExtendedProps;

	// set page title
	useEffect(
		() => setTitle(props.msg.dbTitle, props.msg),
		[ props.msg ]
	);

	// load keywords on mount
	useEffect(
		() => actions.getKeywords(),
		[]
	);

	// put url parameters into the model
	useEffect(
		() => {
			if (
				extendedProps.book		=== undefined &&
				extendedProps.page		=== undefined &&
				extendedProps.persons	=== undefined
			)	return;

			const persons	= extendedProps.persons ?? null;

			const book		= intOrNull(extendedProps.book);
			const page		= intOrNull(extendedProps.page);

			const hasBookAndPage	= book !== null && book !== 0 && page !== null;
			const hasPersons		= persons !== null && persons !== "";

			// TODO: allow all dbopts as url parameters
			const dbOptions:common.DbOptions = {
				...common.DbOptions.empty,
				book:				hasBookAndPage	? book		: null,
				page:				hasBookAndPage	? page		: null,
				persons:			hasPersons		? persons	: null,
				currentResultPage:	1,
			};

			// timing problem – without promise this would be called before refresh function is initialized
			// TODO functional we may need window.setTimeout here due to changes in preact
			void Promise.resolve().then(() => {
				actions.setDbForm(dbOptions);

				// query the database
				// NOTE uses the current dbOptions, not props.dbOptions - the later has not bee updated yet!
				actions.getDb(dbOptions, props.lang);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			// we really only want to do this when the page is loaded
			// extendedProps.book, extendedProps.page, extendedProps.persons
		]
	);

	const keywords	= props.keywords;
	return (
		<main class="Db fadeIn">
			<div class="book-middle fw">
				<div class="book-middle-inner">
					<h1>{props.msg.dbTitle}</h1>
					<div class="Db-hint">(<Link class="text-link" href="/introduction#aboutDb" target="_blank">{props.msg.dbHint}</Link>)</div>
					{	keywords !== null &&
						keywords.keyword1 !== null &&
						keywords.keyword2 !== null &&
							<Search
								lang={props.lang}
								msg={props.msg}
								keywords={keywords}
								dbOptions={props.dbOptions}
							/>
					}
					{	props.db				!== null &&
						props.db.results		!== null &&
						props.db.resultPages	!== null &&
						props.db.offset			!== null &&
							<Results
								results={props.db.results}
								resultPages={props.db.resultPages}
								offset={props.db.offset}
								data={props.db.data}
								personlist={props.db.personlist}
								dbOptions={props.dbOptions}
								lang={props.lang}
								msg={props.msg}
							/>
					}
				</div>
			</div>
		</main>
	);
};

const intOrNull	= (it:string|undefined):number|null	=> {
	if (it === undefined)	return null;
	const out	= parseInt(it);
	return isNaN(out) ? null : out;
};
