import { JSX }			from "preact";

import * as common		from "@spaengler/shared/CommonModel";

import { Messages }		from "@spaengler/frontend/locale";
import { PopupBio }		from "@spaengler/frontend/components/PopupBio";

type DisplayProps = Readonly<{
	msg:		Messages,
	lang:		string,
	screen:		common.EditionScreen,
	spinner:	boolean,
}>;

export const Display = (props:DisplayProps):JSX.Element =>
	<div id="aria-transcription-display" class="transcription-display">
			<div class="transcription-col1">
				<h2 class="">{props.msg.scanTitle}</h2>
				<img
					id={`transcription-page-${props.screen.bookNumber}-${props.screen.pageNumber}`}
					src={`/assets/${props.screen.bookNumber}/${props.screen.scanName}.jpg`}
					class={"transcription-scan" + (props.spinner ? "" : " fadeIn")}
					alt={`Scan der Seite ${props.screen.pageNumber} in Buch ${props.screen.bookNumber}`}
				/>
			</div>
			<div class="transcription-col2">
				<h2 class="">{props.msg.transcriptTitle}</h2>
					<div class={"transcription-transcript" + (props.spinner ? "" : " fadeIn")}>
					{props.screen.ed.map((it, index) =>
						<div
							class={`transcription-transcript-row ${it.writer !== null && it.writer !== ""
							? `writer-${formatWriter(it.writer)}`
							: ""}`}
							{ ...( it.writer !== null && it.writer !== "" && { title: formatWriter(it.writer) } ) }
						>
							<div class="table-col1 table-right">{it.day}</div>
							<div class="table-col2">{it.month}</div>
							<div class="table-col3">{it.parsedItem !== null
								?
									<PopupBio
										parsedItem={it.parsedItem}
										personlist={props.screen.personlist}
										indexRow={index}
										msg={props.msg}
										lang={props.lang}
									/>
								: it.item }
							</div>
							<div class="table-col4 table-right">{it.gulden}</div>
							<div class="table-col5 table-nw">{it.kreuzer} {it.rest}</div>
						</div>
					)}
				</div>
			</div>
		</div>;

const formatWriter	= (writer:string):string => writer.trim().toUpperCase();
