import { JSX } from "preact";

export const EnImpressum = ():JSX.Element => (
	<div>
		<h1>Imprint</h1>

		<p>The account books of the Salzburg merchant family Spängler from 1733 to 1785 – digital</p>

		<p>Edited by Reinhold Reith and Georg Stöger in collaboration with Luisa Pichler-Baumgartner, Maria Johanna Falkner and Katharina Scharf, Salzburg 2019</p>

		<p>Funded by the Austrian Science Fund (FWF) PUD 10</p>

		<h2>Project management and conception</h2>

		<p>Reinhold Reith and Georg Stöger, University of Salzburg</p>

		<h2>Publication</h2>

		<p>City Archive Salzburg<br/>
		Persistent link: <a class="text-link" href="https://www.spaengler-haushaltsbuecher.at">www.spaengler-haushaltsbuecher.at
		</a><br/>
		First published: 1st June 2020 (last updated 1st July 2024)</p>

		<h2>Employees</h2>

		<p>Transcription: Reinhold Reith, Luisa Pichler-Baumgartner, Maria Falkner. The Transcription was co-funded by the Wiesmüller Privatstiftung (Salzburg) and the Stiftungs- und Förderungsgesellschaft of the University of Salzburg.<br/>
		Subject index: Katharina Scharf<br/>
		Digitalisation: City Archive Salzburg, supervised by Dr. Peter F. Kramml</p>

		<h2>Technical implementation and design</h2>

		<p>v3 consulting GmbH (Munich)<br/>
		<a class="text-link" href="https://www.v3consulting.com/">www.v3consulting.com</a></p>

		<h2>Contact and information<br/> according to § 5 E-Commerce Law</h2>

		<p>The information and services on this page are provided by</p>

		<p class="highlight-p">Reinhold Reith and Georg Stöger<br/>
		Department of History – Chair of Economic, Social and Environmental History<br/>
		University of Salzburg<br/>
		Rudolfskai 42<br/>
		A-5020 Salzburg – Austria<br/>
		E-Mail: <a class="text-link" href="mailto:reinhold.reith@sbg.ac.at">reinhold.reith@sbg.ac.at</a> and <a class="text-link" href="mailto:georg.stoeger@sbg.ac.at">georg.stoeger@sbg.ac.at</a>
		</p>
	</div>
);
